import React from "react";
import { makeStyles } from "@material-ui/styles";
import Slider from "react-slick";
import styles from "./styles";

import { CoreHeadingBlock } from "../../blocks/CoreHeadingBlock";
import ProductCardItem from "../ProductCardItem";

const useStyles = makeStyles((theme) => styles(theme));

export default function ProductRelatedProducts(props) {
  const classes = useStyles();

  // console.log(props);

  return (
    <div className={`related-products ${classes.productRelatedProducts}`}>
      <CoreHeadingBlock
        attributes={{
          align: "",
          anchor: "",
          className: "",
          content: props.title,
          level: 2,
          backgroundColor: "",
          textColor: "primary",
          __typename: "WpCoreHeadingBlockAttributes",
        }}
        innerBlocks={[]}
      />
      <Slider
        arrows={true}
        dots={true}
        className="product-slider"
        slidesToShow={3}
        slidesToScroll={3}
        infinite={false}
        centerMode={false}
        responsive={[
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ]}
      >
        {props.products.map((product) => {
          // console.log(product);
          return (
            <div key={"slider-" + product.id}>
              <ProductCardItem
                uri={product.uri}
                productGallery={product.product.productGallery}
                default={props.default}
                code={product.product.code}
                title={product.title}
              />
            </div>
          );
        })}
      </Slider>
    </div>
  );
}
