import slickDot from "../../../images/slick-dot.png";
import slickActive from "../../../images/slick-active.png";

export default theme => ({
    productRelatedProducts: {
        position: 'relative',
        paddingTop: 50,
        paddingBottom: 36,
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            paddingTop: 140,
            flexWrap: 'wrap',
            flexDirection: 'row',
            width: 'calc(100% + 24px)',
            margin: '0 -12px',
        },
        '& h2': {
            flex: '0 0 18.25%',
            maxWidth: '18.25%',
            padding: '0 12px',
        },
        '& .slick-slider': {
            position: 'relative',
            [theme.breakpoints.up('md')]: {
                flex: '0 0 81.25%',
                maxWidth: '81.25%',
                padding: '0 12px',
            },
            '& .slick-slide': {
                '& img': {
                    marginBottom: 12,
                }
            },
            '& button.slick-arrow': {
                display: 'none !important',
                [theme.breakpoints.up('md')]: {
                    display: 'block !important',
                    fontSize: 0,
                    width: 55,
                    height: 55,
                    position: 'absolute',
                    border: '1px solid ' + theme.palette.secondary.main,
                    borderRadius: '50%',
                    bottom: 50,
                    top: 'auto',
                    left: 'auto',
                    '&::before': {
                        content: '""',
                        borderRight: '2px solid ' + theme.palette.secondary.main,
                        borderBottom: '2px solid ' + theme.palette.secondary.main,
                        display: 'inline-block',
                        width: 16,
                        height: 16,
                        position: 'absolute',
                        left: 'calc(50% - 4px)',
                        top: '50%',
                        transform: 'translate(-50%, -50%) rotate(-45deg)',
                        transition: 'bottom .3s ease-in-out',
                    }
                },
                '&.slick-prev': {
                    right: 'calc(123.076% - 55px)',
                    '&::before': {
                        left: 'calc(50% + 4px)',
                        transform: 'translate(-50%, -50%) rotate(135deg)',
                    }
                },
                '&.slick-next': {
                    right: 'calc(123.076% - 125px)',
                }
            },
            '& .slick-dots': {
                position: 'relative',
                margin: '0 0 24px 0',
                width: '100%',
                bottom: 0,
                [theme.breakpoints.up('md')]: {
                    bottom: 24,
                    margin: 0,
                    position: 'absolute',
                    padding: '0 12px',
                    width: '23.076%',
                    right: '100%',
                    textAlign: 'left',
                },
                '& li': {
                    '& button': {
                        padding: 0,
                        width: 24,
                        height: 22,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        fontSize: 0,
                        backgroundImage: 'url(' + slickDot + ')',
                        '&::before': {
                            display: 'none',
                        }
                    },
                    '&.slick-active': {
                        '& button': {
                            backgroundImage: 'url(' + slickActive + ')',
                        }
                    }
                }
            }
        },
    }
});